<template>
    <div class='gallery'>
        <div class='gallery-holder'>
            <VueSlickCarousel 
                    :draggable="images.length > 1 ? true : false" 
                    :touchMove="images.length > 1 ? true : false" 
                    :autoplay="images.length > 1 ? true : false" 
                    :autoplaySpeed=5000
                    :speed=750
                    :arrows="true" 
                    ref="carousel" 
                    @afterChange="onSlideChange"
                >
                <div v-for="(image, id) in images" :key="id" class='image'>
                    <img :src="require('@/assets/gallery/' + image.file)" :alt="image.title">
                </div>                                    
                <template #nextArrow="">
                    <div class="next-arrow-holder">
                        <div class="custom-arrow next">
                            <img src="@/assets/icons/icon_arrow.svg" alt="">
                        </div>
                    </div>
                </template> 
                <template #prevArrow="">
                    <div></div>
                </template>            
            </VueSlickCarousel>
            <div class="ui" v-if="images.length > 1">
                <div class="pagination-holder">
                    <div class="pagination">
                        <div v-for="(image, id) in images" 
                            :key="id" class='image-name' 
                            :class="[id == activeSlide ? 'active' : '', id == images.length - 1 ? 'last' : '']" 
                            @click="activeSlide = id; $refs.carousel.goTo(id)" />
                    </div>
                </div>
                <p class="title">{{images[activeSlide].title}}</p>
            </div>
            <div class="page-title">
                <div>
                    <h1>{{title}}</h1>
                    <h2 v-html="subtitle"/>
                </div>
            </div>
            <img v-if="number !== ''" :src="require('@/assets/icons/' + number)" alt="" class='number'>
        </div>
    </div>    
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
export default {
    name:'Gallery',
    components: {
        VueSlickCarousel
    },
    props:{
        number:{
            type:String,
            default:'',
        },
        images:Array,
        title: {
            type:String,
            default:'{Title}'
        },
        subtitle: {
            type:String,
            default:'{Subtitle}'
        },
    },
    methods: {
        onSlideChange(newSlideIndex) {
            this.activeSlide = newSlideIndex;
        },
    },
    data() {
       return {
           activeSlide: 0
       }
    }
}
</script>

<style lang="scss">   
.gallery {
    .section-title {
        margin-top:-24px;
    }
}
</style>
<style lang="scss" scoped>   
.number {
    position: absolute;
    right:0;
    bottom:-30px;
}
.page-title {
    position:absolute;
    bottom:67px;
    left:0;
    width:100%;
    text-transform: uppercase;
    text-align: left;
    pointer-events: none;
    padding:0 1rem;
    z-index: 1;
    > div {
        max-width: 1024px;
        margin:0 auto;
    }
    h1 {
        font-family: "Gotham Narrow";
        font-size: 0.75rem;
        font-weight: bold;
        letter-spacing: 2px;
        line-height: 1;
        margin:0;
        margin-bottom:10px;
    }
    h2 {
        font-family: "Gotham Narrow";
        font-size: 54px;
        font-weight: 900;
        letter-spacing: 3.18px;
        line-height: 1;
        margin:0;
    }
}
.ui {
    position:absolute;
    bottom:110px;
    right:0;
    text-align: right;
}
.title {
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: "Gotham Narrow";
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 1;
    pointer-events: none;     
    //text-align: right;
}
.pagination-holder {    
    text-align: right;     
    padding-bottom:10px;    
    .image-name {
        display:inline-block;
        width:5px;
        height:5px;
        background:#C2AB64;
        user-select: none;
        pointer-events: all;
        cursor: pointer;
        &:not(.last){
            margin-right:10px;
        }    
        &.active {
            pointer-events: none;
            background:#fff;
        }
    }
}
.gallery-holder {
    position:relative;
    height:100%;
    font-size: 0;    
}
.slick-prev {
    display:none;
}
.next-arrow-holder, .ui {
    max-width: 1024px;
    margin:0 auto;
    position: relative;
}
.custom-arrow {
    width:40px;
    height:40px;
    border-radius: 3px;
    background-color: #FFFFFF;
    padding:13px;
    display:flex;
    justify-content: center;
    box-sizing: border-box;    
    cursor: pointer;

    position:absolute;
    right:0;
    bottom:135px;
}
.gallery {
    width:100%;    
    position:relative;        
    max-height: 560px;
    height:calc(100vh - 125px);
    overflow: hidden;
    &:after {
        content:" ";
        position: absolute;
        left:0;
        top:0;
        height: 100%;
        width: 50%;
        background: linear-gradient(270deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.60) 100%);         
        pointer-events: none;
    }
}
.image {
    width:100%;
    height:100%;
    max-height: 560px;
    outline:none;    
    img {        
        height:calc(100vh - 125px);
        max-height: 560px;
        width:100%;
        object-fit: cover;
        object-position: center;
        pointer-events: none;
    }
} 
/*
@media (min-width:925px) {
    .image img {
        height:calc(100vh - 300px);
    }
}
*/
</style>